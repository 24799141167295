/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderItemsTableRow {
    &-QtyList {
        li {
            font-size: 14px;
            @include mobile {
                font-size: 16px
            }
        }
    }

    &-RowWrapper {
        font-size: 14px;
        word-break: break-word;
        border-bottom: 1px solid $neutral-color-6;
        @include mobile {
            font-size: 16px
        }
        td {
            padding-block-end: 20px;
            color: $font-color-1;

            @include mobile {
                padding-block-end: 10px;

                &:last-child {
                    padding-block-end: 20px;
                }
            }
        }
    }

    &-Name {
        @include desktop {
            font-size: 16px;
        }
    }

    &-OptionLabel {
        &:first-child {
            margin-block-start: 5px;
        }

        font-size: 16px;
    }

    &-OptionValue {
        font-size: 14px;
        @include mobile {
            font-size: 16px
        }
    }
}
