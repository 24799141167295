/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccountMyWishlist {
    --myaccount-wishlist-action-bar-offset: 85px;

    &-Button {
        @include desktop {
            margin-inline-end: 20px;
            margin-inline-start: 20px;
        }
    }

    &-ShareWishlistButton {
        .ShareIcon {
            margin-inline-end: 12px;

            &_isPrimary {
                fill: $white;
            }
        }

        &:not([disabled]):hover .ShareIcon_isPrimary {
            fill: $white;
            transition: ease-out .25s;
        }

        &:not([disabled]) {
            .ShareIcon_isPrimary {
                fill: $primary-color-1;
            }
        }
    }

    .Button {
        padding-inline: 25px;
    }
}
