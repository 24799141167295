/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderInformation {
    &-ColumnTitle {
        margin-block: 10px;

        &:first-child {
            margin-top: 0;
        }

        @include mobile {
            background-color: $default-secondary-base-color;
            border-radius: 2px;
            padding: 10px;
        }
    }

    &-Title {
        color: $font-color-1;

        @include mobile {
            margin-block-end: 10px;
        }
    }

    &-Address {
        .KeyValueTable {
            margin-block-end: 0;

            th,
            td {
                word-break: break-word;
                color: $font-color-1;

                @include desktop {
                    padding-inline-start: 0;
                }
            }

            tbody {
                tr {
                    &:first-of-type {
                        td, th {
                            padding-block-start: 0;
                        }
                    }
                }
            }
        }
    }

    &-PaymentMethod, &-Status {
        display: flex;
        flex-direction: column;

        span:not(:last-child) {
            margin-block-end: 10px;
        }

        @include mobile {
            padding-inline-start: 10px;
        }
    }

    &-RetryPaymentButton {
        padding-inline: 10px;
        margin-top: 40px;
    }
}
