/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrder {

    &-Buttons {
        display: flex;
        margin-block-end: 25px;
        justify-content: space-between;
        align-items: center;

        a:hover {
            text-decoration: none;
        }
    }

    &-OrderStatus {
        @include mobile {
            font-size: 16px;
            margin-left: 0;
            width: max-content !important;
        }
    }

    &-OrderId {
        margin: 10px 0;
        display: flex;
        flex-direction: column;

        @include mobile {
            font-size: 22px;
            flex-direction: column !important;
        }
    }

    &-Reorder {
        margin-inline-end: 20px;
        font-weight: normal;
        font-size: 14px;

        @include mobile {
            font-size: 16px
        }

        &.Button {
            &:active {
                color: var(--link-hover);
            }

            @include hoverable {
                &:hover {
                    color: $primary-color-1;
                }
            }
        }
    }

    &-Reorder,
    &-SubscribeToStatus {
        font-size: 16px;
        font-weight: 600;
        width: max-content;

        @include hoverable {
            &:hover {
                text-decoration: underline;
                color: $primary-color-1;
            }
        }

        &:active {
            text-decoration: none;
            color: var(--link-hover);
        }

        &:focus {
            color: var(--primary-base-color);
            text-decoration: none;
        }
    }

    &-PrintOrder {
        font-weight: normal;

        @include desktop {
            display: block;
            margin-inline-start: auto;
        }
    }

    @include desktop {

        &-Actions {
            .MyAccountOrderTabs {
                &-Wrapper {
                    display: none;
                }
            }
        }
    }
}