/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountInformation {
    &-Wrapper {
        @include mobile {
            margin-bottom: 17px;
        }

        .Form {
            .FieldForm {
                &-Fields {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    @include mobile {
                        flex-direction: column;
                    }
                }

                &-Section {
                    width: 48%;

                    @include mobile {
                        width: 100%;
                    }

                    &WithSpace {
                        @include mobile {
                            margin-block-start: 16px;
                        }
                    }
                }

                &-Legend {
                    font-size: 18px;
                    border-block-end: 1px solid var(--input-border-color);
                    
                    @include mobile {
                        display: none;
                    }
                }
            }

            .Field {
                &_type {
                    &_checkbox {
                        width: max-content;
                    }
                }
            }
        }
    }
}
