/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAccount {
    &-TabContent {
        margin-block-end: 100px;

        @include mobile {
            padding: 0 16px 20px 16px;
            margin-block-end: 10px;
        }

        &_activeTab {
            &_my-orders {
                .MyAccount {
                    &-Heading {
                        @include desktop {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

    @include mobile {
            margin-top: 0;
        
    }

    &NewsletterSubscription .FieldForm-Body {
        gap: 20px;
        display: flex;
        flex-direction: column;
    }
}
